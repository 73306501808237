/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppExperimentalFeatures } from '../models/AppExperimentalFeatures';
import type { BuilderSessionState } from '../models/BuilderSessionState';
import type { ChatSessionBuilder } from '../models/ChatSessionBuilder';
import type { ContentBuilderMessage } from '../models/ContentBuilderMessage';
import type { ContentBuilderMessageGroup } from '../models/ContentBuilderMessageGroup';
import type { LazyApp } from '../models/LazyApp';
import type { LazyAppCreate } from '../models/LazyAppCreate';
import type { LazyAppCustomDomain } from '../models/LazyAppCustomDomain';
import type { LazyAppCustomDomainCreate } from '../models/LazyAppCustomDomainCreate';
import type { LazyAppCustomDomainUpdate } from '../models/LazyAppCustomDomainUpdate';
import type { LazyAppMainInstanceInfo } from '../models/LazyAppMainInstanceInfo';
import type { LazyAppUpdate } from '../models/LazyAppUpdate';
import type { LazyAppVersion } from '../models/LazyAppVersion';
import type { LazyAppWithMinimalAppVersion } from '../models/LazyAppWithMinimalAppVersion';
import type { LazyAppWithMinimalAppVersionWithSessionStateId } from '../models/LazyAppWithMinimalAppVersionWithSessionStateId';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppService {

    /**
     * Retrieves list of Lazy Apps
     * Retrieves the list of the user's Lazy Apps in JSON format
     * @param orgId
     * @returns LazyAppWithMinimalAppVersion Successful Response
     * @throws ApiError
     */
    public static appGetAllApps(
        orgId?: string,
    ): CancelablePromise<Array<LazyAppWithMinimalAppVersion>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app',
            query: {
                'org_id': orgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Creates new Lazy App
     * Creates a new Lazy App
     * @param requestBody
     * @returns LazyApp Successful Response
     * @throws ApiError
     */
    public static appCreateApp(
        requestBody: LazyAppCreate,
    ): CancelablePromise<LazyApp> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Lazy App Template
     * Generates community template from the Lazy App
     * @param appId
     * @param orgId
     * @returns LazyApp Successful Response
     * @throws ApiError
     */
    public static appGenerateTemplate(
        appId: string,
        orgId?: string,
    ): CancelablePromise<LazyApp> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app/{app_id}/template',
            path: {
                'app_id': appId,
            },
            query: {
                'org_id': orgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Publish Lazy App Template
     * Publishes a new Lazy App Template or updates an existing template
     * @param appId
     * @param name
     * @param description
     * @param videoUrl
     * @returns LazyApp Successful Response
     * @throws ApiError
     */
    public static appPublishTemplate(
        appId: string,
        name: string,
        description: string,
        videoUrl: string,
    ): CancelablePromise<LazyApp> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/app/{app_id}/template',
            path: {
                'app_id': appId,
            },
            query: {
                'name': name,
                'description': description,
                'video_url': videoUrl,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Revert Lazy App
     * Reverts to a previous version on a Lazy App
     * @param appId
     * @param builderSessionStateId
     * @returns ChatSessionBuilder Successful Response
     * @throws ApiError
     */
    public static appRevertToPreviousVersion(
        appId: string,
        builderSessionStateId: string,
        tabId: string,
    ): CancelablePromise<ChatSessionBuilder> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app/{app_id}/revert/{builder_session_state_id}',
            path: {
                'app_id': appId,
                'builder_session_state_id': builderSessionStateId,
            },
            query: {
                tab_id: tabId
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Gets a Lazy App by ID
     * Retrieves the information about a Lazy App in JSON format
     * @param appId
     * @returns LazyAppWithMinimalAppVersionWithSessionStateId Successful Response
     * @throws ApiError
     */
    public static appGetApp(
        appId: string,
    ): CancelablePromise<LazyAppWithMinimalAppVersionWithSessionStateId> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}',
            path: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Updates a Lazy App
     * Changes information about a Lazy App. Returns the updated app
     * @param appId
     * @param requestBody
     * @returns LazyApp Successful Response
     * @throws ApiError
     */
    public static appUpdateApp(
        appId: string,
        requestBody: LazyAppUpdate,
    ): CancelablePromise<LazyApp> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/app/{app_id}',
            path: {
                'app_id': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Gets the current session for a Lazy App
     * Retrieves the current builder state for a Lazy App
     * @param appId
     * @param numMessages Number of messages in session to return
     * @returns BuilderSessionState Successful Response
     * @throws ApiError
     */
    public static appGetAppSession(
        appId: string,
        numMessages: number = 20,
    ): CancelablePromise<BuilderSessionState> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/session',
            path: {
                'app_id': appId,
            },
            query: {
                'num_messages': numMessages,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update the session
     * Adds a new message to the session and returns the updated version with LLM response.
     * @param appId
     * @param tabId
     * @param requestBody
     * @param isLlmIndependentRequest
     * @returns BuilderSessionState Stream of BuilderSessionState objects
     * @throws ApiError
     */
    public static appUpdateAppSession(
        appId: string,
        tabId: string,
        requestBody: ContentBuilderMessage,
        isLlmIndependentRequest: boolean = false,
    ): CancelablePromise<BuilderSessionState> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/app/{app_id}/session',
            path: {
                'app_id': appId,
            },
            query: {
                'tab_id': tabId,
                'is_llm_independent_request': isLlmIndependentRequest,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * App-specific Secrets Storage JWT token
     * Returns a new Secrets Storage JWT token for a Lazy App
     * @param appId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static appGetAppSecretsStorageJwtToken(
        appId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/secrets_storage_jwt_token',
            path: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Information about the main app instance
     * Returns details about the main app instance
     * @param appId
     * @param testing
     * @returns LazyAppMainInstanceInfo Successful Response
     * @throws ApiError
     */
    public static appGetMainInstanceInfo(
        appId: string,
        testing: boolean,
    ): CancelablePromise<LazyAppMainInstanceInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/main_instance_info',
            path: {
                'app_id': appId,
            },
            query: {
                'testing': testing,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Retrieves the current version of an app
     * Returns information on the current published or testing version
     * @param appId
     * @param testing
     * @returns LazyAppVersion Version retrieved
     * @throws ApiError
     */
    public static appGetCurrentAppVersion(
        appId: string,
        testing: boolean = false,
    ): CancelablePromise<LazyAppVersion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/current_version',
            path: {
                'app_id': appId,
            },
            query: {
                'testing': testing,
            },
            errors: {
                403: `The user does not have testing access to the app`,
                404: `There is no current published version for the app`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get app custom domains
     * Returns lists of all custom domains for the app
     * @param appId
     * @returns LazyAppCustomDomain Successful Response
     * @throws ApiError
     */
    public static appGetAllCustomDomainsForApp(
        appId: string,
    ): CancelablePromise<Array<LazyAppCustomDomain>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/custom_domain',
            path: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create app custom domain
     * Creates a new custom domain for the app
     * @param appId
     * @param requestBody
     * @returns LazyAppCustomDomain Successful Response
     * @throws ApiError
     */
    public static appCreateAppCustomDomain(
        appId: string,
        requestBody: LazyAppCustomDomainCreate,
    ): CancelablePromise<LazyAppCustomDomain> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app/{app_id}/custom_domain',
            path: {
                'app_id': appId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deletes app custom domain
     * Deletes existing custom domain for the app
     * @param appId
     * @param domainId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static appDeleteAppCustomDomain(
        appId: string,
        domainId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/app/{app_id}/custom_domain/{domain_id}',
            path: {
                'app_id': appId,
                'domain_id': domainId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update app custom domain
     * Updates existing custom domain for the app
     * @param appId
     * @param domainId
     * @param requestBody
     * @returns LazyAppCustomDomain Successful Response
     * @throws ApiError
     */
    public static appUpdateAppCustomDomain(
        appId: string,
        domainId: string,
        requestBody: LazyAppCustomDomainUpdate,
    ): CancelablePromise<LazyAppCustomDomain> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/app/{app_id}/custom_domain/{domain_id}',
            path: {
                'app_id': appId,
                'domain_id': domainId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Renew app custom domain certificates
     * Renews all existing custom domain certificates
     * @returns any Successful Response
     * @throws ApiError
     */
    public static appRenewCertificateForCustomDomains(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/app/custom_domain/renew_certificates',
        });
    }

    /**
     * Verify app custom domain
     * Verify the custom domain for the app
     * @param appId
     * @param domainId
     * @returns LazyAppCustomDomain Successful Response
     * @throws ApiError
     */
    public static appVerifyAppCustomDomain(
        appId: string,
        domainId: string,
    ): CancelablePromise<LazyAppCustomDomain> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/app/{app_id}/custom_domain/{domain_id}/verify',
            path: {
                'app_id': appId,
                'domain_id': domainId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get custom domain's app id
     * Returns app id for the app to which custom domain belongs
     * @param domainName
     * @returns string Successful Response
     * @throws ApiError
     */
    public static appGetCustomDomainByName(
        domainName: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/custom_domain/{domain_name}',
            path: {
                'domain_name': domainName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get verified custom domain for app
     * Returns verified custom domain name for app id
     * @param appId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static appGetVerifiedCustomDomainByAppIdInternal(
        appId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/verified_custom_domain_internal',
            path: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get verified custom domain for app
     * Returns verified custom domain name for app id
     * @param appId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static appGetVerifiedCustomDomainByAppIdExternal(
        appId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/verified_custom_domain',
            path: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get verified custom domain for app
     * Returns verified custom domain name for app id
     * @param appId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static appCheckVerifiedCustomDomainAccessibility(
        appId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/check_verified_custom_domain_accessibility',
            path: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Verifies if app link is accessible
     * Returns verified custom domain name for app id
     * @param appId
     * @param testing 
     * @returns string Successful Response
     * @throws ApiError
     */
    public static appCheckAppLinkAccessibility(
        appId: string,
        testing: boolean,

    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/check_app_link_accessibility',
            path: {
                'app_id': appId,
            },
            query: {
                'testing': testing,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get builder session messages
     * Get paginated builder session state messages
     * @param appId
     * @param beforeDatetime Datetime to filter messages
     * @param numMessages Number of messages to return
     * @returns ContentBuilderMessageGroup Successful Response
     * @throws ApiError
     */
    public static appGetAppBuilderMessages(
        appId: string,
        beforeDatetime?: string,
        numMessages: number = 20,
    ): CancelablePromise<Array<ContentBuilderMessageGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/messages',
            path: {
                'app_id': appId,
            },
            query: {
                'before_datetime': beforeDatetime,
                'num_messages': numMessages,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set experimental feature
     * Set experimental feature for the app
     * @param appId
     * @param featureName
     * @param value
     * @returns any Successful Response
     * @throws ApiError
     */
    public static appSetAppExperimentalFeature(
        appId: string,
        featureName: string,
        value: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app/{app_id}/experimental/{feature_name}',
            path: {
                'app_id': appId,
                'feature_name': featureName,
            },
            query: {
                'value': value,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get experimental features
     * Get experimental features for the app
     * @param appId
     * @returns AppExperimentalFeatures Successful Response
     * @throws ApiError
     */
    public static appGetAppExperimentalFeatures(
        appId: string,
    ): CancelablePromise<AppExperimentalFeatures> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/{app_id}/experimental',
            path: {
                'app_id': appId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Migrate app session states to incremental
     * Migrate app session states to incremental
     * @param appId
     * @param maxStates
     * @returns any Successful Response
     * @throws ApiError
     */
    public static appMigrateAppToIncrementalStates(
        appId: string,
        maxStates: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/app/{app_id}/migrate_to_incremental',
            path: {
                'app_id': appId,
            },
            query: {
                'max_states': maxStates,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get app icon using file name
     * Get app icon from GCS using file name
     * @param fileName
     * @returns string Successful Response
     * @throws ApiError
     */
    public static appGetAppIcon(
        fileName: any,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/app/app_icon/{file_name}',
            path: {
                'file_name': fileName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
